<template>
  <div class="enquetes--resultado">
    <Voltar />

    <h1 class="titulo">Você gostou do look do André Marques?</h1>

    <div class="grafico">
      <p>Antes</p>
      <div>
        <!-- a ideia seria que o valor do resultado da enquete seja entregue no valor do widh inline  abaixo, onde estaria o 73, assim como no span -->
        <div class="grafico__barra" style="width: 73%"></div>
      </div>
      <span class="grafico__valor">73%</span>

      <p>Depois</p>
      <div>
        <!-- a ideia seria que o valor do resultado da enquete seja entregue no valor do widh inline  abaixo, onde estaria o 27, assim como no span -->
        <div class="grafico__barra" style="width: 27%"></div>
      </div>
      <span class="grafico__valor">27%</span>
    </div>

    <div class="bt">
      <a href="">Fechar</a>
    </div>
  </div>
</template>
<script>
import Voltar from '../../components/Voltar'
export default {
  components: { Voltar }
}
</script>
